import React from 'react'
import { Button, Box, Grid } from '@mui/material'

import api from 'utils/api'
import Layout from 'components/Layout'
import Image from 'next/image'

export async function getServerSideProps(ctx) {
  if (ctx.query && ctx.query.token) {
    const { data: userData } = await api.post(
      '/users/authenticate',
      {},
      { headers: { authtoken: ctx.query.token } },
    )
    ctx.res.writeHead(302, {
      'Set-Cookie': `user=${JSON.stringify({
        id: userData.id,
        email: userData.email,
        token: userData.token,
        name: userData.displayName,
      })}; path=/`,
      Location: '/',
    })
    ctx.res.end()
  }

  return { props: {} }
}

export default function LoginPage() {
  return (
    <Layout noNavbar title="Login">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '85vh' }}>
        <Grid item xs={12}>
          <Image src="/logo-scenic-amp.svg" alt="Scenic Logo" width="500px" height="64px" />
        </Grid>
        <Grid item xs={12}>
          <Box my={10} mr={1}>
            <Button
              onClick={async () => {
                const { data: salesforceOAuthURL } = await api.get('/salesforce/oauth')
                window.location.href = salesforceOAuthURL
              }}
              variant="contained"
              color="info"
              size="large"
              disableElevation>
              Login with Salesforce
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}
